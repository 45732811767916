import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
const Index = {
  name: 'warehousing-record1',
  components: {
    PageHeader,
    Adsearch
  },
  mixins: [tableMixin],
  data() {
    return {
      isShow: false,
      visible1: false,
      visible: false,
      SelectType: 1,
      adSearchForm: [],
      // 通过物料
      adSearchForOthers1: [
        { name: '预入库单号', value: 'ASNID' },
        { name: '采购订单号', value: 'PurchaseOrderID' },
        { name: '物料编号', value: 'InvCode' },
        { name: '品牌名称', value: 'BrandName' },
        { name: '物料名称', value: 'InvName' },
        { name: '物料类型', value: 'MaterialTypeName' },
        { name: '物料简称', value: 'InvShotName' },
        { name: '供应商', value: 'SellCompanyName' },
        { name: 'DI码', value: 'DINum' },
        { name: '注册证号', value: 'CertificateName' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '生产厂家', value: 'OrigFactory' },
        { name: '入库单号', value: 'InStockOrderID' },
        { name: '操作人', value: 'OperationNickName' },
        { name: '时间', value: 'outdate', date: true }
      ],
      // 通过订单 暂无
      adSearchForOthers: [
        { name: '入库单号', value: 'InStockOrderID' },
        { name: '操作人', value: 'OperationName' },
        { name: '入库类型', value: 'InStockType' },
        { name: '出库时间', value: 'outdate', date: true }
      ],
      formSearch: {
        PageIndex: '', // 页数,
        PageSize: '', //  每页显示条数,
        KeyWords: '', // 关键字,
        ASNID: '', // 预入库单号,
        PurchaseOrderID: '', // 采购订单号,
        InvCode: '', // 物料编号,
        BrandName: '', //  品牌,
        InvName: '', // 物料名称,
        MaterialTypeName: '', // 物料类型,
        InvShotName: '', // 物料简称,
        SellCompanyName: '', // 供应商,
        CertificateName: '', // 注册证号,
        DINum: '', //  UDI码,
        ManuPartNum: '', // 原厂编号,
        OrigFactory: '', //  生产厂家,
        BeginDate: '', //  开始时间,
        EndDate: '', // 结束时间,
        InStockOrderID: '', // 入库单号,
        OperationNickName: '' // 操作人
      },
      selects: [],
      formIdx: '',
      isShow1: true
    }
  },
  // beforeUpdate() {
  //   this.$nextTick(() => { // 在数据加载完，重新渲染表格
  //     this.$refs.tableRef.doLayout()
  //   })
  // },
  watch: {
    selects(a) {
      if (a.length === 0) return
      a.map(item => {
        this.formIdx += `'${item.IDX}'` + ','
      })
    }
  },
  methods: {
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 按物料
    async getTableDataAsync(page, pageSize) {
      console.log('getTableDataAsyncgetTableDataAsyncgetTableDataAsync')
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      let response = {}
      if (this.SelectType === 1) {
        response = await this.$api.QueryOrderASNReceiveLogList(this.formSearch)
        this.tableData = response.Message
        this.tableTotalSize = response.Total
      }
      if (this.SelectType === 2) {
        response = await this.$api.QueryInStockOrderList(this.formSearch)
        this.tableData = response.Message
        this.tableTotalSize = response.Total
      }
      console.log('response', response)

      return response
    },
    Adsearch() {
      this.isShow = true
      this.adSearchForm = []
      if (this.SelectType === 1) {
        this.adSearchForm = this.adSearchForOthers1
      }
      if (this.SelectType === 2) {
        this.adSearchForm = this.adSearchForOthers
      }
    },
    searchAd() {
      if (this.formSearch.outdate.length === 2) {
        this.formSearch.BeginDate = this.formSearch.outdate[0]
        this.formSearch.EndDate = this.formSearch.outdate[1]
      }
      this.getTableDataAsync()
      // if (this.SelectType === 1) {
      //   this.getTableDataAsync()
      // }
      // if (this.SelectType === 2) {
      //   this.getTableDataAsync1()
      // }
      this.isShow = false
    },
    selectType(type) {
      this.tableData = []
      this.visible = false
      this.currentPage = 1
      this.SelectType = type
      this.getTableDataAsync() //
      // if (type === 1) {
      //   this.getTableDataAsync() // 物料
      // }
      // if (type === 2) {
      //   this.getTableDataAsync1()// 订单
      // }
    },
    // 导出
    exportFile() {
      if (this.SelectType !== 1) return this.$message.info('只有物料可以导出')
      this.visible1 = !this.visible1
    },
    // 全部导出
    allExport() {
      this.formSearch.ExportType = 0
      this.visible1 = false
      this.$refs.ExportLoading.open()
      this.formSearch.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.formSearch.SelectIDList = []
      this.$api.ExportOrderASNReceiveLogExcelByUserField(this.formSearch).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('入库记录')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：', res.RetMsg)
      })
    },
    // 批量导出
    piLiangExport() {
      this.formSearch.ExportType = 1
      this.formSearch.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      if (this.selects.length <= 0) return this.$message.info('没有选中任何一项！')
      const arr = []
      this.selects.forEach(item => {
        arr.push(item.IDX)
      })
      this.formSearch.SelectIDList = arr
      this.visible1 = false
      this.$refs.ExportLoading.open()

      this.$api.ExportOrderASNReceiveLogExcelByUserField(this.formSearch).then(res => {
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('入库记录')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：', res.RetMsg)
      })
    },
    // 下载
    downFile(row) {
      if (row.FileUrl) {
        this.$api.GetFirstCampFiles({
          FileURL: row.FileUrl
        }).then(res => {
          this.$minCommon.downloadFile(res.Message, '.' + row.FileUrl.split('.')[row.FileUrl.split('.').length - 1])
        })
        return
      }
      return this.$message.info('该条数据没有附件链接')
    }
  }
}
export default Index
